@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .word-spacing{
    word-spacing: normal;
  }

  .text-last-right{
    text-align-last: right;
  }
}

@font-face {
  font-family: 'Sequel';
  src: url('./fonts/Sequel\ Sans\ Light\ Body.ttf');
}

@font-face {
  font-family: 'Sharp';
  src: url('./fonts/Sharp\ Grotesk\ Book\ 20\ Regular.ttf');
}

html{
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar{
  display: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background-color: black;
}
